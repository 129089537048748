

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
.dropdown{
  margin-top: 120px;
  position: relative;
  background: #008080;
  color: white;
  border-radius: 8px;
  padding:0 10px;
}
.drop-item{
  color: white;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: .4rem;
}
.fa-sign-out-alt{
padding-top: 27px;
}
.profile{
  position: absolute;
}
:root {
  --primary-color:  #008080;
  --secondary-color: #01d293;
  --body-bg:#fff;
  --card-01-bg: linear-gradient(#ef621c, #e1424e);
  --card-02-bg: linear-gradient(#01d293, #56c57a);
  --card-03-bg: #725cff;
  --card-04-bg: #2884ff;
  --heading-color: #fff;
  --small-text-color: #808191;
  --recommend-car01-bg: #e1dfa4;
  --recommend-car02-bg: #e3ecf1;
  --recommend-car03-bg: #f4e3e5;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "DM Sans", sans-serif;
  background: var(--body-bg) !important;
}




ul {
  list-style: none;
}

a {
  text-decoration: none !important;
  color: unset;
}

img {
  width: 100%;
}
.login_btn{
  background-color: #01d293 !important;
  color: #fff !important;
}

.login_page input{
  background-color: transparent;
  outline: none;
}
.login_page input:focus{
  background-color: transparent;
  box-shadow:none;
  color: #fff;
}
.table_img{
  width: 40px ;
  border-radius: 12px;
  height: 40px;
}
.table>:not(caption)>*{
  border-bottom: 1px solid #fff ;
}

.table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width:0px !important;
  /* box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); */
  height: 55px !important;
}

.tables_serach{
  padding: 8px;
  border: 1px solid #fff;
  background-color:transparent;
  outline: none;
  width: 50%;
  color: #fff;
}
.tables_serach:focus{
  border: 2px solid  #01d293;
  outline: none;
}

.navw{

  background-color:#01d293;
  width: 80px;


}
.navw a{
  
  color: #ffff;
}



.add_products{
  
  right:120px;
  position: fixed;
  background-color: #01d293;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  top: 75%;
  
}
.add_products i{
  padding:0;
  margin: 0;
  justify-content: center;
  margin-left: 17px;
  color: #fff;
 text-align: center;
  font-size: 40px;
  
  


}

.bookings {
  margin-top: 100px;
  padding: 0px 30px;
  padding-bottom: 50px;
}
  .booking__wrapper{
    background: var(--primary-color);
    padding: 15px;
    border-radius: 12px;
  }
  .booking__wrapper table{
    color: #fff;
  }

  .booking__wrapper table td{
    height: 60px;
  }
.booking__title {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 2rem;
}

.filter__widget-wrapper {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.filter__widget-01 select,
.filter__widget-02 select {
  border: none;
  padding: 7px 20px;
  border-radius: 50px;
  background: var(--primary-color);
  color: #fff;
  cursor: pointer;
  margin-bottom: 2rem;
}

.car__item {
  padding: 20px;
  border-radius: 5px;
  background: #b7ffe913 !important;
  cursor: pointer;

}

.car__item-tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.car__item-tile h3 {
  font-size: 1.3rem;
  color: var(--heading-color);
  font-weight: 400;
}

.car__item-tile span i {
  font-size: 1.3rem;
  color: var(--small-text-color);
  cursor: pointer;
}

.car__item-top p {
  color: var(--small-text-color);
  font-size: 0.9rem;
  font-weight: 400;
}

.car__item-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.car__bottom-left {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.car__bottom-left p {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: rgba(255, 255, 255, 0.829);
  font-weight: 300 !important;
}

.car__bottom-left p i {
  color: var(--secondary-color);
}

.car__rent {
  color: rgba(255, 255, 255, 0.829);
  font-size: 1.1rem;
}

.booking__car-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}

.car__item-tile h3 {
  font-size: 1rem;
  font-weight: 400;
}

.car__item-top {
  margin-bottom: 15px;
}

.car__img {
  margin-bottom: 20px;
}

.car__item:nth-child(2) .car__img img,
.car__item:nth-child(6) .car__img img,
.car__item:nth-child(8) .car__img img {
  width: 100%;
}

.car__item:nth-child(4) .car__img img {
  width: 100%;
}

.main__layout {
  margin-left: 125px;
}

.tre{
  margin-top: 27px !important;
}






@media screen and (max-width:400px) {
  .tre{
    text-align: left !important;
  }
  
  .slipss p{
      font-size: 2px !important;
  }
  .slips_img{
    width: 20px !important;
  }
  .times{
    font-size: 2px;
    font-weight: bold;
    margin: 0;
  }

  .items_items{
    margin: 0 !important;
  }
.linesw{
  margin: 0 !important;
}
.total_bills{
  font-size: 2px !important;
}
.thank{
  margin: 0px !important;
}

.tables_bills {
  font-size: 2px !important;
}
.tables_bills td{
  font-size: 2px !important;
}
.tables_bills tbody{
  line-height: 2px;
}

.add_items{
  font-size: 3px !important;
}

.tables_ss tr td{
  font-size: 3px !important;
}

}