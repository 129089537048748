
.sorting{

  white-space: nowrap;

}
.hamburger-icon {
  font-size: 24px; /* Adjust the size as needed */
  cursor: pointer;
  color: white;
  display: none;
}

@media (max-width: 390px) {
.layout{
  margin-left: -70%;
}

  .sidebar {
    width: 164px;
    height: 100%;
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    z-index: 999;
    background: #008080;
    padding: 0px 0px;
  }
  .sidebar__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 5%);
    margin-top: 102px;
  }
  .booking__car-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }
  .bookings {
    margin-top: 100px;
    padding: 0px;
    padding-bottom: 50px;
  }

  .nav__list {
    display: flex;
    row-gap: 1.5rem;
    flex-direction: column;
    color: var(--small-text-color);
  }
  .nav__link{
    font-size: .6rem;
  }
  .hamburger-icon {
    font-size: 24px; /* Adjust the size as needed */
    cursor: pointer;
    color: white;
    display: block;
  }
  .dashboard__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
     column-gap: 1rem;
  }
  .card__content h4 {
    color: var(--heading-color);
    font-size: .4rem;
    font-weight: 400;
  }
  .stats {
    background: var(--primary-color);
    padding: 26px;
    border-radius: 5px;
    height: 172px;
    padding-bottom: 50px;
  }
  .card__content span {
    color: var(--heading-color);
    font-size: .7rem;
  }
  .single__card {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
  }

  .burger-icon{
    display:block;
  }
  .top__nav {
    background: var(--primary-color);
    height: 70px !important;
    left: 0px !important;
    line-height: 70px;
    position: fixed;
    top: 0;
    width: calc(111% - 50px)!important;
    z-index: 999;
  }
  .content{
    margin-left: -135px;
  }
}

.abc {
  height: 10px !important;
  width: 150px !important;
  padding: 15px !important;
}
.search-btn {
  height: 33px !important;
  line-height: 0 !important;
  padding: 6px !important;
}
.search-input{
  width: 300px;
}
.img-gol{
  height: 60px;
  width: 60px;
  border-radius: 30%;
}
.dataTable{

  overflow-x: scroll;

  margin-bottom: 20px;

}

.dataTables_filter{

  margin-bottom: 20px;

}

.dt-buttons{

  position: absolute;

}
.log-pos {
  width: 100%;
  height: 120px;
  margin-top: 30%;
}


.dt-button{
  background:rgb(1,210,147);
  color:white;
  border: none;
  padding: 1rem;
  border-radius: 5px;
}

.padd-1{
  padding: 1rem !important;
}
.background-orange{
  background-color: #E2960D;
  padding: 5px;
}
.paginate_button{
  color:white !important;
}



.dataTables_wrapper .dataTables_paginate .paginate_button {
  color:white !important;
}




.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color:#01d293 !important;
}

.nav-pills .nav-link {
  background-color: transparent !important;
  border: 1px solid #ffff !important;
  color: #ffff !important;
}
